import "@fontsource/rakkas";
import "@fontsource/noto-sans-hk";
import "@fontsource/lora";
import { WrapRootElement } from "./src/components/WrapRootElement";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
    element,
}) => {
    return <WrapRootElement>{element}</WrapRootElement>;
};
